import { Box, Stack, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CommonModel from "../../../components/dialog/Common";
import NumberInput from "../../../components/inputs/normalInput/number";
import { formatPrice, getFormatedDate } from "../../../utils/common";
import { RUPEES_SYMBOL, SUBSCRIPTION_ENUM, SUBSCRIPTION_STATUS } from "../../../utils/constant";
import { subscriptionCustomPayment, subscriptionDetails } from "../action";
const DetailsSubscriptions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const subscription = useParams();
    const [open, setOpen] = useState();
    const { details } = useSelector((state) => state.subscriptionSlice);
    useEffect(() => {
        dispatch(subscriptionDetails(subscription?.id, () => { }));
    }, [subscription, dispatch])

    return (
        <>

            <Stack direction={"row"} alignItems={"center"} spacing={3} mb={2}>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    sx={{
                        minHeight: '42px'
                    }}
                >
                    <Typography variant="h1" className="page-title">
                        Plan Details
                    </Typography>
                    <Stack
                        className="backText"
                        direction={"row"}
                        gap={1}
                        alignItems={"center"}
                        sx={{ cursor: "pointer" }}
                        onClick={() => { }}
                    >
                        <svg
                            width="16"
                            height="12"
                            viewBox="0 0 16 12"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M8.5 7.66667L5.16667 4.33333L8.5 1M4.33333 7.66667L1 4.33333L4.33333 1"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M5.1665 4.33331H10.9998C11.8839 4.33331 12.7317 4.6845 13.3569 5.30962C13.982 5.93474 14.3332 6.78259 14.3332 7.66665C14.3332 8.5507 13.982 9.39855 13.3569 10.0237C12.7317 10.6488 11.8839 11 10.9998 11H10.1665"
                                stroke="#0077FF"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        <Typography variant="h5" color={"primary"} onClick={() => navigate("/subscriptions")}>
                            Back
                        </Typography>

                    </Stack>

                </Stack>
                {details?.type === SUBSCRIPTION_ENUM.CUSTOM && details?.status === SUBSCRIPTION_STATUS.DRAFT && parseInt(details.planCost) > 0 && <>
                    <Box sx={{ flexGrow: 1 }} />
                    <button
                        className="btnPrimary"
                        type="submit"
                        onClick={() => setOpen(true)} // remove by ranveer sir for production
                    >
                        Send Payment Link
                    </button></>}
            </Stack>
            <div className="card p-4">
                <div className="row g-3">
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="dataView">
                            <label>Plan Name</label>
                            <span>{details?.name}</span>
                        </div>
                    </div>
                    {
                        details?.type === SUBSCRIPTION_ENUM.STANDARD && <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="dataView">
                                <label>Plan Indexing</label>
                                <span>{details?.ordering || "N/A"}</span>
                            </div>
                        </div>

                    }
                    {details?.type === SUBSCRIPTION_ENUM.CUSTOM && <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="dataView">
                            <label>Plan Cost</label>
                            <span>{RUPEES_SYMBOL + formatPrice(parseFloat(details?.planCost)) || "N/A"}</span>
                        </div>
                    </div>
                    }
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="dataView">
                            <label>Type of Plan</label>
                            <span>{details?.type}</span>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="dataView">
                            <label>Plan Status</label>
                            <span>{details?.status}</span>
                        </div>
                    </div>
                    {details?.type === SUBSCRIPTION_ENUM.CUSTOM && <><div className="col-lg-3 col-md-4 col-sm-6 col-12">
                        <div className="dataView">
                            <label>Start Date</label>
                            <span>{details?.startDate ? getFormatedDate(details?.startDate, "DD MMMM YYYY") : "N/A"}</span>
                        </div>
                    </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="dataView">
                                <label>End Date</label>
                                <span>{details?.endDate ? getFormatedDate(details?.endDate, "DD MMMM YYYY") : "N/A"}</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="dataView">
                                <label>Company</label>
                                <span>{details?.companyName || "N/A"}</span>
                            </div>
                        </div>
                    </>
                    }
                </div>
            </div>
            <div className="card cretePlan p-4">
                <div className="row">
                    <div className="col-12 mb-4">
                        <Typography variant="h4" component="h4" sx={{ fontSize: '18px' }}>
                            Product Selection
                        </Typography>
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                        <div className='borderBox'>
                            <Typography variant="h4" component="h4" sx={{ fontSize: '15px' }}>
                                Influencer Discovery
                            </Typography>
                            <Box className='numbBox' component="form" noValidate autoComplete="off">
                                <div className='row g-3 align-items-center'>
                                    <div className='col-md-6 col-sm-6 col-6'>
                                        <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                            Search View
                                        </Typography>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-6'>
                                        <p className="m-0">{details?.searchView || "N/A"}</p>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-6'>
                                        <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                            Profile View
                                        </Typography>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-6'>
                                        <p className="m-0">{details?.profileView || "N/A"}</p>
                                    </div>

                                </div>
                                <div className='cntCard'>
                                    <Typography variant="h5" component="h5" sx={{ fontSize: '15px', fontWeight: '500', marginBottom: '10px' }}>
                                        Contact Details
                                    </Typography>
                                    <div className='row g-3 align-items-center'>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                Phone Number
                                            </Typography>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <p className="m-0">{details?.phoneNumber || "N/A"}</p>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                Email ID
                                            </Typography>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <p className="m-0">{details?.email || "N/A"}</p>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                    <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12'>
                        <div className='borderBox'>
                            <Typography variant="h4" component="h4" sx={{ fontSize: '15px' }}>
                                Campaign Management
                            </Typography>
                            <Box className='numbBox' component="form" noValidate autoComplete="off">
                                <div className='row g-3 align-items-center'>
                                    <div className='col-md-6 col-sm-6 col-6'>
                                        <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                            Unique User Logins
                                        </Typography>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-6'>
                                        <p className="m-0">{details?.uniqueUsersLogin || "N/A"}</p>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-6'>
                                        <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                            Influencer Approved
                                        </Typography>
                                    </div>
                                    <div className='col-md-6 col-sm-6 col-6'>
                                        <p className="m-0">{details?.influencerApproved || "N/A"}</p>
                                    </div>

                                </div>
                                <div className='cntCard'>
                                    <Typography variant="h5" component="h5" sx={{ fontSize: '15px', fontWeight: '500', marginBottom: '10px' }}>
                                        Campaigns
                                    </Typography>
                                    <div className='row g-3 align-items-center'>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                Paid
                                            </Typography>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <p className="m-0">{details?.paid || "N/A"}</p>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                Barter
                                            </Typography>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <p className="m-0">{details?.barter || "N/A"}</p>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                Affiliate
                                            </Typography>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <p className="m-0">{details?.affiliate || "N/A"}</p>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <Typography variant="p" component="p" sx={{ fontSize: '14px', color: 'rgba(0, 0, 0, 0.8)', fontWeight: '500', marginBottom: '0px' }}>
                                                Performance
                                            </Typography>
                                        </div>
                                        <div className='col-md-6 col-sm-6 col-6'>
                                            <p className="m-0">{details?.performance || "N/A"}</p>
                                        </div>
                                    </div>
                                </div>
                            </Box>
                        </div>
                    </div>
                </div>
            </div>
            {details?.type === SUBSCRIPTION_ENUM.STANDARD &&
                <div className='card p-4'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-9 col-sm-12 col-12'>
                            <div className='row align-items-center justify-content-between'>
                                <div className='col-4'>
                                    <Typography variant="h4" component="h4" sx={{ fontSize: '18px' }}>
                                        Plan Cost
                                    </Typography>
                                </div>
                                <div className='col-8 text-end'>
                                    <NumberInput
                                        className="ttldiscount"
                                        name={"totalDiscount"}
                                        isRequired={false}
                                        startAdornment={true}
                                        startAdornmentText=" Discount (%)"
                                        type={"number"}
                                        focused={false}
                                        isDisabled={true}
                                        label={("")}
                                        placeholder={""}
                                        sizeval="medium"
                                        values={formatPrice(details?.totalDiscount)}
                                        onChange={(e) => { }}

                                    />
                                </div>
                            </div>
                            <div className='discountTable mt-3'>
                                <TableContainer>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}></TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Monthly (&#x20B9;)</TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Quarterly (&#x20B9;)</TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Yearly (&#x20B9;)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            <TableRow
                                            >
                                                <TableCell component="th" scope="row" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Actual Rates
                                                </TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{formatPrice(details?.monthlyRate) || "N/A"}</TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{formatPrice(details?.quarterlyRate) || "N/A"}</TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{formatPrice(details?.yearlyRate) || "N/A"}</TableCell>
                                            </TableRow>

                                            <TableRow>
                                                <TableCell component="th" scope="row" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>Discounted Rates
                                                </TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{formatPrice(details.monthlyDiscountedRate) || "N/A"}</TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{formatPrice(details.quarterlyDiscountedRate) || "N/A"}</TableCell>
                                                <TableCell align="center" sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>{formatPrice(details.yearlyDiscountedRate) || "N/A"}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                </div>}

            <CommonModel
                open={open}
                handleDialogClose={() => setOpen(false)}
                dialogTitle={"Send Payment"}
                dialogPara={"Are you sure, you want to send payment link?"}
                nvYes={"Yes"}
                nvCancel={"No"}
                yesHandle={() => {
                    let payload = { amount: String(details.planCost), type: "Subscription", id: details.id, subscriptionType: "Custom" }
                    setOpen(false);
                    dispatch(subscriptionCustomPayment(payload))
                }}
            />
        </>

    );

}
export default DetailsSubscriptions;
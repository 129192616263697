import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, Grid, ListItemButton, Typography } from "@mui/material";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CommonModel from '../../../components/dialog/Common';
import TalkToExpert from '../../../components/dialog/talktoExpert';
import { getParamMasterList } from '../../../layout/action';
import { updateCommonData } from '../../../redux/commonSlice';
import { formatPrice } from '../../../utils/common';
import { SUBSCRIPTION_ENUM } from '../../../utils/constant';
import { getAllActivePlans, subscriptionPayment } from '../action';
import { resetSubscription } from '../subscriptionSlice';

const SubscriptionsListing = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [tab, setTab] = useState(1);
    const [open, setOpen] = useState(false);
    const [payload1, setPayload] = useState({});
    const [open1, setOpen1] = useState(false);
    const [type, setType] = useState("");
    const { activePlans, currentActivePlan } = useSelector((state) => state.subscriptionSlice);
    const { fixedSubscriptions } = useSelector((state) => state.commonSlice);


    useEffect(() => {
        dispatch(getParamMasterList());
        dispatch(getAllActivePlans());
        dispatch(updateCommonData({
            status: false
        }));
        return () => {
            dispatch(resetSubscription());
        }
    }, []);

    const handleClick = () => {
        window.location.href = "/"
    }

    return <> <Grid container>
        <Grid sm={12} lg={12} className='showhandCursor' md={12} xs={12} p={1} onClick={() => navigate("/subscription/active")}>
            <Typography variant="subtitle2" gutterBottom>
                <ArrowBackIcon /> &nbsp;Back to inflyx
            </Typography>
        </Grid>
    </Grid>

        <Grid container bgcolor={"#F4F4F4"} p={1} pl={2} mb={4}>
            <Grid item sm={12} lg={8} md={8} xs={12} p={1}>
                <div className='subsPlanHead'>
                    <Typography variant="h4" fontSize={24} gutterBottom>
                        Subscription Plans
                    </Typography>
                    {activePlans?.filter((v) => v.isActive == 1)?.length == 0 &&
                        <Typography variant="h4" pt={1} gutterBottom>
                            Your are using free version limits feature.Unlock the power of Inflyx
                        </Typography>}
                </div>
            </Grid>
            <Grid item sm={12} lg={4} md={4} xs={12} p={1} textAlign={"right"}>
                <div className='viewActPlan'>
                    <Link to="/subscription/active" className='btnPrimary text-decoration-none d-inline-block'>View Active Plan</Link>
                </div>
            </Grid>
            <Grid item sm={12} lg={12} md={12} xs={12} >
                <div className='px-4 pt-4 pb-5'>
                    <div className='dashPgeTbs subsPageTabs'>
                        <div className='viewDetTabs dashboardPage mx-auto'>
                            <button className={`${tab === 1 ? "active" : ""}`}
                                onClick={() => setTab(1)}
                            >
                                Monthly
                            </button>
                            <button className={`${tab === 2 ? "active" : ""}`}
                                onClick={() => setTab(2)}
                            >
                                Quarterly
                            </button>
                            <button className={`${tab === 3 ? "active" : ""}`}
                                onClick={() => setTab(3)}
                            >
                                Yearly
                            </button>
                        </div>
                    </div>
                </div>
            </Grid>
            <div className='packagePlans w-100'>
                <div className='container-fluid'>
                    {activePlans.length > 0 && activePlans.map((v) => {
                        return <div className='planItem'>
                            <label className='capitalize-first-letter'>{v.name}</label>
                            <h2><span>₹</span>{tab === 1 && formatPrice(v.monthlyDiscountedRate || v.monthlyRate)}{tab === 2 && formatPrice(v.quarterlyDiscountedRate || v.quarterlyRate)}{tab === 3 && formatPrice(v.yearlyDiscountedRate || v.yearlyRate)}</h2>
                            <ListItemButton
                                className='get-plane-btn'

                                onClick={(e) => {
                                    //return false; // remove by ranveer sir as disscussed with anuj sir
                                    if (v.isActive) return
                                    let amount = v.monthlyDiscountedRate || v.monthlyRate || 0
                                    let subscriptionType = "Monthly";
                                    if (tab == 2) {
                                        amount = v.quarterlyDiscountedRate || v.quarterlyRate || 0;
                                        subscriptionType = "Quarterly";
                                    }
                                    if (tab == 3) {
                                        amount = v.yearlyDiscountedRate || v.yearlyRate || 0;
                                        subscriptionType = "Yearly";
                                    }
                                    let payload = { amount: String(amount), type: "Subscription", id: v.id, subscriptionType: subscriptionType }

                                    if (currentActivePlan?.type === SUBSCRIPTION_ENUM.CUSTOM || v.ordering < currentActivePlan.ordering) {
                                        setOpen1(true);
                                        setPayload(payload);
                                    } else {
                                        dispatch(subscriptionPayment(payload))
                                    }


                                }}
                            >
                                <Typography
                                    variant="h4"
                                    sx={{ background: v.isActive ? "#0077FF !important" : "", }}
                                //sx={{ background: "#D1D1D1 !important" }}
                                >
                                    {v.isActive ? "ACTIVE" : "GET THIS PLAN"}
                                </Typography>

                            </ListItemButton>
                            <Box className="packageDetails">
                                <Typography variant="p" component="p"  >
                                    {v.searchView ? <CheckIcon color='success' fontSize='small' /> : <ClearIcon color='error' fontSize='small' />} {v.searchView} Search Views
                                </Typography>
                                <Typography variant="p" component="p"  >
                                    {v.profileView ? <CheckIcon color='success' fontSize='small' /> : <ClearIcon color='error' fontSize='small' />} {v.profileView} Profile Views
                                </Typography>

                                <Typography variant="p" component="p" >
                                    {v.phoneNumber ? <CheckIcon color='success' fontSize='small' /> : <ClearIcon color='error' fontSize='small' />} {v.phoneNumber} Phone Number Views
                                </Typography>
                                <Typography variant="p" component="p">
                                    {v.uniqueUsersLogin ? <CheckIcon color='success' fontSize='small' /> : <ClearIcon color='error' fontSize='small' />}  {v.uniqueUsersLogin} Unique User logins
                                </Typography>

                                <Typography variant="p" component="p" >
                                    {v.influencerApproved ? <CheckIcon color='success' fontSize='small' /> : <ClearIcon color='error' fontSize='small' />}  {v.influencerApproved} Influencers approved
                                </Typography>
                                <Typography variant="p" component="p" >
                                    {v.paid ? <CheckIcon color='success' fontSize='small' /> : <ClearIcon color='error' fontSize='small' />} {v.paid} Paid Campaigns
                                </Typography>
                                <Typography variant="p" component="p" >
                                    {v.barter ? <CheckIcon color='success' fontSize='small' /> : <ClearIcon color='error' fontSize='small' />} {v.barter} Barter Campaigns
                                </Typography>
                                <Typography variant="p" component="p">
                                    {v.affiliate ? <CheckIcon color='success' fontSize='small' /> : <ClearIcon color='error' fontSize='small' />} {v.affiliate} Affiliate Campaigns
                                </Typography>
                                <Typography variant="p" component="p">
                                    {v.performance ? <CheckIcon color='success' fontSize='small' /> : <ClearIcon color='error' fontSize='small' />} {v.performance} Performance Campaigns
                                </Typography>
                            </Box>

                        </div>
                    })
                    }
                    {fixedSubscriptions.length > 0 && fixedSubscriptions.map((v) => {
                        return <div className='planItem'>
                            <label >{v.name}</label>
                            <h2>{v.description}</h2>
                            {v.name !== "Enterprice" ? <ListItemButton onClick={() => {
                                setOpen(true);
                                setType(v.type)
                            }} className='get-plane-btn _blank'

                            >
                                <Typography
                                    variant="h4"
                                    className='text-uppercase'
                                >
                                    {v.buttonText}
                                </Typography>
                            </ListItemButton> :
                                <ListItemButton
                                    className='get-plane-btn'
                                    onClick={() => {
                                        setOpen(true);
                                        setType(v.type)
                                    }}
                                >
                                    <Typography
                                        variant="h4"
                                        className='text-uppercase'
                                    >
                                        {v.buttonText}
                                    </Typography>
                                </ListItemButton>}

                            <Box className="packageDetails">
                                {v.content?.map((v) => {
                                    return <Typography component="p">
                                        {v.status ? <CheckIcon className="green" fontSize='small' /> : <ClearIcon className="red" fontSize='small' />} {v.text}
                                    </Typography>
                                })}
                            </Box>
                        </div>
                    })
                    }
                </div >
            </div >

        </Grid >
        <CommonModel
            open={open1}
            handleDialogClose={() => setOpen1(false)}
            dialogTitle={"Plan Degrading"}
            dialogPara={`Please note that degrading from an existing plan would immediately end your existing plan benefits and would start the new plan that you purchase instead. Are you sure you want to continue?`}
            nvCancel={"Cancel"}
            nvYes={"Yes"}
            yesHandle={() => {
                dispatch(subscriptionPayment(payload1))
                setOpen1(false)
            }}
        />
        <TalkToExpert open={open} handleClose={() => setOpen(false)} type={type} />
        {/* <Alert /> */}
    </>


}

export default SubscriptionsListing
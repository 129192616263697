import axios from "axios";
import { updateGlobalLoader } from "../../components/backdrop/backdropSlice";
import { updateCommonData } from "../../redux/commonSlice";
import { Alert } from "../../utils/Alert";
import { api } from "../../utils/api";
import { encodedURI, getCompanyid, removeEmptyValues, removeEmptyValuesFromFormData } from "../../utils/common";
import { CAMPAIGN_TYPES, SUCCESS } from "../../utils/constant";
import { endpoints } from "../../utils/endpoints";
import { updateCompany } from "../company/companySlice";
import { updateCampaign } from "./campaignSlice";



export const getAllCampaigns = () => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        const { limit, page, filters } = getState().campaignSlice;
        console.log("limit", limit, page)
        const filterData = encodedURI(removeEmptyValues(filters));
        api.getApiCall(
            endpoints.getAllCampaign,
            `?limit=${limit}&offset=${page * limit}&filter=${filterData}`,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                let res = respData.data;
                dispatch(updateCampaign({ listing: res.result.data, totalCount: res.result.count, totalPages: res.result.count }));
            },
            (errors) => {
                dispatch(updateGlobalLoader(false));
                console.log("error", errors);
            }
        );
    };
};

export const getCampaignInfluencersListNew = (id, journeyId = 0) => {
    return (dispatch, getState) => {

        api.getApiCall(
            endpoints.campaign + id,
            ``,
            (respData) => {
                let res = respData.data;
                let contentJourney = journeyId ? res?.result?.campaignContent?.find((v) => v.id === journeyId)?.campaignContentJourney : [];
                console.log("journeyId------", journeyId);
                console.log("linkJourney------", contentJourney);
                dispatch(updateCampaign({ campaignInfluencersList: res.result, scriptDetails: contentJourney, linkJourney: contentJourney }));
                //dispatch(updateCampaign({ campaignInfluencersList: res.result.data, totalCount: res.result.count, totalPages: res.result.count }));
            },
            (errors) => {
                console.log("error", errors);
            }
        );
    };
};





export const getMyCampaigns = () => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        const { limit, page, myCampaignsFilters } = getState().campaignSlice;
        const myCampaignsFilters1 = encodedURI(removeEmptyValues(myCampaignsFilters));
        api.getApiCall(
            endpoints.myCampaigns,
            `?limit=${limit}&offset=${page * limit}&filter=${myCampaignsFilters1}`,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                let res = respData.data;
                dispatch(updateCampaign({ myCampaigns: res.result.data, totalCount: res.result.count, totalPages: res.result.count }));
            },
            (errors) => {

                console.log("error", errors);
            }
        );
    };
};

export const addCampaignInfo = (values, endpoint) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        const { invitedInfluencers, formData } = getState().campaignSlice;
        const formData1 = values;

        const newFormData = removeEmptyValues(formData1);
        const formNewData = new FormData();
        //let pltform = newFormData.plateform;




        let creator = {};
        let deliverablesNew = [{ plateform: values.platform, deliverables: values?.selectedDeliverables?.filter((v) => v != null), post: values.post }];
        Object.entries(newFormData).forEach(([key, value]) => {
            if (["gender", "influncerType", "selectedCategories", 'creatorLocation'].includes(key)) {
                creator[key] = value;
            } else if (key === "campaignApplyPref") {
                creator["campaignApplyingPreferences"] = value.length > 0;

            }
            else if (['platform', 'deliverables', 'selectedDeliverables', 'post', 'brand', 'products', 'invitedInfluencers'].includes(key)) {

            } else if (["productShipmentDate", "shipmentDetails"].includes(key) && newFormData.type === CAMPAIGN_TYPES.BARTER) {
                if (key === "shipmentDetails") {
                    formNewData.append(key, value.length > 0);
                } else {

                    formNewData.append(key, value);
                }
            }
            else if (["kpi"].includes(key) && newFormData.type === CAMPAIGN_TYPES.AFFILIATE) {
                formNewData.append(key, JSON.stringify(value));
            }
            else {
                formNewData.append(key, value);
            }
        });
        //formNewData.append("creator", JSON.stringify(creator));
        formNewData.append("products", JSON.stringify(formData.products)); //
        formNewData.append("companyId", formData.userId || getCompanyid());
        if (typeof formData.brandLogo !== "object") {
            formNewData.delete('brandLogo');
        }
        if (typeof formData.bannerImage !== "object") {
            formNewData.delete('bannerImage');
        }

        // if( formData?.invitedInfluencers)

        formNewData.append("deliverables", JSON.stringify(deliverablesNew));
        if (invitedInfluencers.length > 0) {
            //formNewData.append("invitedInfluencers", JSON.stringify(invitedInfluencers));
        }

        // if (formData?.mode == "add") {
        //     formNewData.append("creator", JSON.stringify({ gender: "", influncerType: "", selectedCategories: [], creatorLocation: [], campaignApplyingPreferences: false }));
        // }
        api.postApiCall(
            endpoint,
            formNewData,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === SUCCESS) {
                    if (respData.data?.result?.id) {
                        dispatch(updateCampaign({
                            formData: {
                                ...formData, campaignId: respData.data.result.id
                            }
                        }));
                    }
                    //sAlert(1, respData.data.status);
                } else {

                    dispatch(updateCommonData({
                        message: respData.data.message || "Api error",
                        title: "Failed",
                        img: "/failed.png",
                        status: true
                    }));
                    //Alert(2, respData.data.message);
                }
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data.message);
            }
        );
    };
};

export const addNewCampaign = (postData, navigate) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        const { formData } = getState().campaignSlice;
        const newData = removeEmptyValues(postData);


        const formNewData = new FormData();

        formNewData.append("type", formData.type);
        formNewData.append("demand", formData.demand);
        formNewData.append("companyId", formData.userId || getCompanyid());
        if (formData?.campaignId) {
            formNewData.append("campaignId", formData?.campaignId || "0");
        }
        Object.entries(newData).forEach(([key, value]) => {
            formNewData.append(key, value);
        });

        if (typeof postData.bannerImage !== "object") {
            formNewData.delete('bannerImage');
        }
        if (typeof postData.bannerImage !== "object" && postData.bannerImage == "") {
            formNewData.append('bannerImage', postData.bannerImage);
        }
        api.postApiCall(
            endpoints.addCampaign,
            formNewData,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData?.data?.result?.error?.message) {
                    dispatch(updateCommonData({
                        message: respData?.data?.result?.error?.message,
                        title: "Warning",
                        img: "/warning.png",
                        status: true
                    }));
                }
                dispatch(updateCampaign({
                    formData: {
                        ...formData,
                        campaignId: respData.data.result.id || formData?.campaignId
                    }
                }));

            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data.message);
            }
        );
    };
};


export const addCampaign = (navigate) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        const { formData, invitedInfluencers } = getState().campaignSlice;

        const newFormData = removeEmptyValues(formData);
        const formNewData = new FormData();

        let pltform = newFormData.plateform;
        let creator = {};
        let audience = {}
        let deliverablesNew = { "plateform": pltform };
        Object.entries(newFormData).forEach(([key, value]) => {
            if (["gender", "influncerType", "selectedCategories", "location"].includes(key)) {
                creator[key] = value;
            } else if (key === "campaignApplyPref") {
                formNewData.append("campaignApplyingPreferences", value.length > 0);

            }
            else if (key === "shipmentDetails") {
                if (CAMPAIGN_TYPES.BARTER === newFormData.type) {
                    formNewData.append(key, value.length > 0);
                }
            }
            else if (['audioPrefGender', 'ageGroup', "creatorLocation"].includes(key)) {
                audience[key] = value;
            } else if (key === "deliverables") {
                deliverablesNew["deliverables"] = value;
            }
            else if (['plateform', 'storyPost',].includes(key)) {

            } else if (["productShipmentDate", "shipmentDetails"].includes(key) && newFormData.type === CAMPAIGN_TYPES.BARTER) {
                if (key === "shipmentDetails") {
                    formNewData.append(key, value.length > 0);
                } else {
                    formNewData.append(key, value);
                }
            }
            else if (["kpi"].includes(key) && newFormData.type === CAMPAIGN_TYPES.AFFILIATE) {
                formNewData.append(key, JSON.stringify(value));
            }
            else {
                formNewData.append(key, Array.isArray(value) ? JSON.stringify(value) : value);
            }
        });


        formNewData.append("audience", JSON.stringify(audience));
        formNewData.append("deliverables", JSON.stringify(deliverablesNew));
        formNewData.append("invitedInfluencers", JSON.stringify(invitedInfluencers));

        const data = removeEmptyValuesFromFormData(formNewData);


        api.postApiCall(
            endpoints.addCampaign,
            data,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === SUCCESS) {
                    Alert(1, respData.data.status);
                    navigate("/campaign/submit-review");
                } else {
                    Alert(2, respData.data.message);
                }

            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data.message);
            }
        );
    };
};

export const bidAcceptedOrRejected = (postData, id, campaignId) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.patchApiCall(
            endpoints.bidAcceptedOrRejectedEndpoint + `${id}`,
            postData,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                dispatch(getCampaignInfluencersList(campaignId))
                let res = respData.data;
                if (res.status === "FAILURE") {
                    dispatch(updateCommonData({
                        message: res.message,
                        title: respData.data.result?.customErrorNumber !== 100023 ? "Insufficient Credits" : "",
                        img: "/sub_exp.png",
                        status: true,
                        getPlanButton: res.message == "Insufficient Balance" ? false : true
                    }));
                }

            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};


export const bidNegotiation = (postData, id) => {
    return (dispatch, getState) => {
        console.log("campaignId11111111111", postData)
        dispatch(updateGlobalLoader(true));
        api.patchApiCall(
            endpoints.bidNegotiatedEndpoint + `/${id}`,
            postData,
            (respData) => {
                dispatch(updateGlobalLoader(false));

                dispatch(getCampaignInfluencersList(postData.campaignId))

                let res = respData.data;
                Alert(1, res.message);
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};

export const requestPaymentProcess = (postData, setHistoryOpen) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.markAsInProcess,
            postData,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                let res = respData.data;
                if (respData.data.status === "FAILURE") {
                    dispatch(updateCommonData({
                        message: res.message,
                        title: respData.data.result?.customErrorNumber !== 100023 ? "Insufficient Credits" : "",
                        img: "/sub_exp.png",
                        status: true,
                        getPlanButton: res.message == "Insufficient Balance" ? false : true
                    }));
                } else {
                    setHistoryOpen(false);
                    dispatch(updateCommonData({
                        message: "Payment requested successfully done",
                        title: "Success",
                        img: "/success.png",
                        status: true
                    }));
                    dispatch(getCampaignInfluencersListNew(postData.campaignId))
                }
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};

export const approveReleasePayment = (postData) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.approvedReleasePayment,
            postData,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                let res = respData.data;
                if (respData.data.status === "FAILURE") {
                    dispatch(updateCommonData({
                        message: res.message,
                        title: "Error",
                        img: "/sub_exp.png",
                        status: true
                    }));
                } else {
                    dispatch(updateCommonData({
                        message: "Success",
                        title: "",
                        img: "/success.png",
                        status: true
                    }));
                    dispatch(getCampaignInfluencersListNew(postData.campaignId))
                }
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};

export const moveToApprovedInfluencers = (data, navigate, setOpen, type) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.movedToApprovedEndpoint,
            data,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                //dispatch(getCampaignInfluencersList(postData.campaignId))
                let res = respData.data;
                if (respData.data.status === SUCCESS) {
                    dispatch(updateCompany({
                        message: "Influencers moved successfully to the Approved Influencers list.",
                        title: "",
                        img: "/success2.png",
                        status: true,
                        buttonText: "Approved Influencers",
                        redirect: "/my-campaigns/approved-influencer/" + data.campaignId
                    }));
                    //navigate("/my-campaigns/approved-influencer/" + data.campaignId)
                } else {
                    dispatch(updateCompany({
                        message: res.message || "Api error",
                        title: "Insufficient Balance!",
                        img: "/remove1.png",
                        status: true,
                        buttonText: "Top-up Now",
                        redirect: "/wallet"
                    }));
                    //navigate("/my-campaigns/negotiated-influencers/" + data.campaignId)
                }
                dispatch(getCampaignInfluencersList(data.campaignId));

                //Alert(1, res.message);
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};

export const getCampaignInfluencersList = (id, journeyId = 0) => {
    return (dispatch, getState) => {
        const { limit, page, filters, appliedInfluencerId } = getState().campaignSlice;
        const filterData = encodedURI(removeEmptyValues(filters));
        api.getApiCall(
            endpoints.getInvitedInfluencers + id,
            `?limit=${limit}&offset=${page * limit}&filter=${filterData}`,
            (respData) => {
                let res = respData.data;
                //let contentJourney = journeyId ? res?.result?.campaignContent?.find((v) => v.id === journeyId)?.campaignContentJourney : [];
                //dispatch(updateCampaign({ campaignInfluencersList: res.result, scriptDetails: contentJourney }));


                dispatch(updateCampaign({ campaignInfluencersList: res.result.data, totalCount: res.result.count, totalPages: res.result.count, influencersBidDetails: appliedInfluencerId ? res.result.data?.find((v) => v.id === appliedInfluencerId) : res.result.data }));
            },
            (errors) => {
                console.log("error", errors);
            }
        );
    };
};
export const contentScriptAcceptOrReject = (postData, id, campaignId, type) => {
    return (dispatch, getState) => {
        const { journeyId } = getState().campaignSlice;
        dispatch(updateGlobalLoader(true));
        let endpoint = type === "script" ? endpoints.campaignContentJourney : endpoints.campaignContentJourneyLink;
        api.postApiCall(
            endpoint + `${id}`,
            postData,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                // setOpenmodel(false);
                //dispatch(getCampaignInfluencersList(campaignId, journeyId));
                dispatch(getCampaignInfluencersListNew(campaignId, journeyId));
                let res = respData.data;
                Alert(1, res.message);
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};


export const updateProductStatus = (postData) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.campaignContentProductStatus,
            postData,
            (respData) => {
                dispatch(updateGlobalLoader(false));

                // dispatch(getCampaignInfluencersList(postData.campaignId));
                dispatch(getCampaignInfluencersListNew(postData.campaignId))


                let res = respData.data;
                Alert(1, res.message);
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};

export const deleteCampaign = (id) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.deleteApiCall(
            endpoints.campaign + `${id}`,
            ``,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                dispatch(getAllCampaigns());
                let res = respData.data;
                Alert(1, res.message);
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};

export const removeInfluencersfromCampaign = (campaignId, id) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.deleteApiCall(
            endpoints.removeFromcampaign + `${id}`,
            ``,
            (respData) => {
                dispatch(updateGlobalLoader(false));

                if (respData.data.status === SUCCESS) {
                    dispatch(updateCommonData({
                        message: "Influencers removed successfullyCreator Management.",
                        title: "",
                        img: "/success.png",
                        status: true
                    }));
                } else {
                    dispatch(updateCommonData({
                        message: respData.data.message || "Api error",
                        title: "Failed",
                        img: "/failed.png",
                        status: true
                    }));
                    //Alert(2, respData.data.message);
                }
                dispatch(getCampaignInfluencersListNew(campaignId, id));
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};
export const getInfluencerProfilesSearch = () => {
    return (dispatch, getState) => {
        var { advanceFilter, searchedInfluencersInvitedIds, filters, campaignId, influencerslimit, influencersPage } = getState().campaignSlice;
        let location = advanceFilter?.location?.filter((v) => v !== " Pan India");
        const cleanedAdvanceFilter = {
            ...advanceFilter,
            location: location
        };
        const filter = encodedURI(removeEmptyValues(cleanedAdvanceFilter));
        const filterSearch = encodedURI(removeEmptyValues(filters))
        const campId = campaignId ? `&campaignId=${campaignId}` : "";
        dispatch(updateGlobalLoader(true));
        console.log("influencerslimit", influencerslimit)
        api.getApiCall(
            endpoints.getAllInfluencers,
            `?advanceFilter=${filter}&filter=${filterSearch}${campId}&limit=${influencerslimit}&offset=${influencersPage * influencerslimit}`,
            (respData) => {
                dispatch(updateGlobalLoader(false));

                dispatch(
                    updateCampaign({
                        // userListing: respData.data.result.data?.filter((v) => !invitedIds.includes(v.id)),
                        totalInfluencersCount: respData.data.result.count,
                        searchedInfluencers: respData.data.result.data?.filter((v) => !searchedInfluencersInvitedIds.includes(v.id)),
                    })
                );
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    }
}


export const getInfluencerProfilesSuggestions = () => {
    return (dispatch, getState) => {
        const { advanceFilter, formData, searchedInfluencersInvitedIds } = getState().campaignSlice;
        // let filters = {
        //     createrGender: formData?.gender,
        //     location: formData?.creatorLocation?.toString(),
        //     influencerType: formData?.influncerType?.toString(),
        //     categories: formData?.selectedCategories?.toString(),
        // }
        const filterData = encodedURI(removeEmptyValues(advanceFilter));
        dispatch(updateGlobalLoader(true));
        api.getApiCall(
            endpoints.getAllInfluencersSuggestions + `/` + formData.campaignId, //${formData.campaignId}
            `?q=${filterData}`,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                dispatch(
                    updateCampaign({
                        searchedInfluencers: respData.data.result?.filter((v) => !searchedInfluencersInvitedIds.includes(v.id))
                    })
                );
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    }
}

export const getInfluencerProfilesList = (id) => {
    return (dispatch, getState) => {

        dispatch(updateGlobalLoader(true));
        api.getApiCall(
            endpoints.getAllInfluencers,
            ``,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                dispatch(
                    updateCampaign({
                        influencersList: respData.data.result.data
                    })
                );
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    }
}

export const getPostCampaign = (id, infId = "") => {
    return (dispatch, getState) => {

        dispatch(updateGlobalLoader(true));
        api.getApiCall(
            endpoints.postcampaign + id + `?influencerId=` + infId,
            ``,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                dispatch(
                    updateCampaign({
                        postCampaigndata: respData.data.result.details,
                        topInfluencer: respData.data.result.topInfluencer
                    })
                );
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    }
}

export const getPostCampaignBreakDown = (id) => {
    return (dispatch, getState) => {

        dispatch(updateGlobalLoader(true));
        api.getApiCall(
            endpoints.postInsightsBreakDown + id,
            ``,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                dispatch(
                    updateCampaign({
                        postbreakDown: respData.data.result
                    })
                );
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    }
}

export const inviteAdditionalInfluencer = (data) => {
    return (dispatch, getState) => {

        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.addCampaignToInfluencer + `/${data.id}`,
            { "userId": data.invitedInfluencers },
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === SUCCESS) {
                    dispatch(getInfluencerProfilesList(data.id))
                    dispatch(updateCommonData({
                        message: "Influencers invited successfully",
                        title: "Success",
                        img: "/success.png",
                        status: true
                    }));
                } else {
                    Alert(2, respData.data.message);
                }
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data.message);
            }
        );
    }
}



export const inviteInfluencerByCampaign = () => {
    return (dispatch, getState) => {
        const { invitedInfluencers, formData, userListing } = getState().campaignSlice;

        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.addCampaignToInfluencer + `/${formData.campaignId}`,
            { "userId": invitedInfluencers },
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === SUCCESS) {
                    dispatch(getInfluencerProfilesSuggestions())
                    Alert(1, respData.message);

                } else {
                    Alert(2, respData.data.message);
                }
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data.message);
            }
        );
    }
}


export const invitedInfluencerBySearch = () => {
    return (dispatch, getState) => {
        const { searchedInfluencersInvitedIds, formData, searchedInfluencers } = getState().campaignSlice;

        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.addCampaignToInfluencer + `/${formData.campaignId}`,
            { "userId": searchedInfluencersInvitedIds },
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === SUCCESS) {
                    dispatch(updateCampaign({ searchedInfluencers: searchedInfluencers.filter((v) => !searchedInfluencersInvitedIds.includes(v.id)) }))
                    dispatch(getInfluencerProfilesSearch());
                    Alert(1, respData.data.message);
                } else {
                    Alert(2, respData.data.message);
                }
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data.message);
            }
        );
    }
}


export const inviteInfluencerBySuggestions = () => {
    return (dispatch, getState) => {
        const { invitedIds, formData, userListing } = getState().campaignSlice;

        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.addCampaignToInfluencer + `/${formData.campaignId}`,
            { "userId": invitedIds },
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === SUCCESS) {
                    dispatch(
                        updateCampaign({
                            userListing: userListing?.filter((v) => !invitedIds.includes(v.id))
                        })
                    );
                    Alert(1, respData.message);
                    // navigate("/campaign/review");
                } else {
                    Alert(2, respData.data.message);
                }
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data.message);
            }
        );
    }
}


export const inviteInfluencerByCampaignManualy = (values, setOpenmodel) => {
    return (dispatch, getState) => {
        const { formData } = getState().campaignSlice;
        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.addCampaignToInfluencer + `/${formData.campaignId}`,
            values,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === SUCCESS) {
                    Alert(1, respData.data.message);
                } else {
                    Alert(2, respData.data.message);
                }
                setOpenmodel(false);
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data.message);
            }
        );
    }
}
export const getPresignedurl = (key, extention, file, callback) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.postApiCall(
            endpoints.presignedurl,
            { fileName: key, campaignId: "0", fileMimetype: extention },//`?key=${key}.${extention}&fileType=image/${extention}`,
            (respData) => {
                callback(respData?.data?.result.playUrl)
                dispatch(
                    updateCampaign({
                        referenceContentUrl: respData?.data?.result.playUrl
                    })
                );
                const response = async () => {
                    await axios.put(
                        respData?.data?.result.url,
                        file,
                        {
                            headers: {
                                'Content-Type': file.type,
                            },
                        }
                    ).then((res) => {
                        dispatch(updateGlobalLoader(false));
                        console.log("ress", res)
                    }).catch((err) => {
                        console.log("file error", err);
                    });
                }
                response();
                // const formData = new FormData();
                // formData.append("file", file);
                // const response = async () => axios.put(respData?.data?.result.url, formData, {}).then((res) => {
                //     console.log("presign urlssssssssss", res)
                //     // dispatch(
                //     //     updateCampaign({
                //     //         fileUrl: import.meta.env.VITE_IMAGE_URL + `${key}.${extention}`,
                //     //     })
                //     // );
                //     // dispatch(updateGlobalLoader(false));
                // });
                // response();
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.message);
            }
        );
    };
};

export const changeAdminPassword = (postData) => {
    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.patchApiCall(
            endpoints.updateAdmin,
            postData,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                let res = respData.data;
                Alert(1, res.message);
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};


// export const getPresignedUrl = (values, callback) => {
//     return (dispatch, getState) => {
//         //const { formData } = getState().campaignSlice;
//         dispatch(updateGlobalLoader(true));
//         api.postApiCall(
//             endpoints.uploadFile,
//             values,
//             (respData) => {
//                 dispatch(updateGlobalLoader(false));
//                 callback(respData.data.result.url);
//                 //Alert(1, respData.data.message);
//             },
//             (error) => {
//                 dispatch(updateGlobalLoader(false));
//                 let { data } = error;
//                 Alert(2, data.message);
//             }
//         );
//     }
// }
export const getCampaignById = (id) => {
    return (dispatch, getState) => {
        api.getApiCall(
            endpoints.campaign + id,
            ``,
            (respData) => {
                dispatch(updateCampaign({ formData: respData.data.result }));
            },
            (errors) => {
                console.log("error", errors);
            }
        );
    };
};


export const getPostAffiliateInsights = (id, influcerId = "") => {
    return (dispatch, getState) => {
        api.getApiCall(
            endpoints.postAffiliateInsights + "/" + id + "?influencerId=" + influcerId,
            ``,
            (respData) => {
                dispatch(updateCampaign({ postAffiliateInsights: respData.data.result }));
            },
            (errors) => {
                console.log("error", errors);
            }
        );
    };
};

export const getBrandLogoById = (id) => {
    return (dispatch, getState) => {
        const { formData } = getState().campaignSlice;
        api.getApiCall(
            endpoints.campaign + id,
            ``,
            (respData) => {

                dispatch(updateCampaign({ formData: { ...formData, brandLogo: respData.data.result.brand.brandImage } }));
            },
            (errors) => {
                console.log("error", errors);
            }
        );
    };
};

export const getCampaignByIdForDetailsPage = (id, callback) => {
    return (dispatch, getState) => {
        api.getApiCall(
            endpoints.campaign + id,
            ``,
            (respData) => {
                callback(respData.data.result);
                // dispatch(updateCampaign({ formData: respData.data.result }));
            },
            (errors) => {
                console.log("errorqqqqqqqqqqq", errors);
            }
        );
    };
};

export const updateCampaignStatus = (postData) => {

    return (dispatch, getState) => {
        dispatch(updateGlobalLoader(true));
        api.patchApiCall(
            endpoints.updateCampaignStatus,
            postData,
            (respData) => {
                dispatch(updateGlobalLoader(false));
                if (respData.data.status === "FAILURE") {
                    let res = respData.data;
                    dispatch(updateCommonData({
                        message: res.message,
                        title: "Insufficient Credits",
                        img: "/sub_exp.png",
                        status: true,
                        getPlanButton: false
                    }));
                } else {
                    dispatch(updateCampaign({ msgDisplay: "" }));
                }
            },
            (error) => {
                dispatch(updateGlobalLoader(false));
                let { data } = error;
                Alert(2, data?.msg);
            }
        );
    };
};



//updateCampaignStatus